import {
	Collapse,
	createStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Theme,
	Tooltip,
	Typography,
	withStyles,
	WithStyles,
} from '@material-ui/core'
import { blue, blueGrey, grey } from '@material-ui/core/colors'
import moment from 'moment'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { TableHeader, TableItem } from '../../services/model'
import { Sort } from '../../utils/sortingService'
import { HeaderItem } from '../AllPartnerships/AllPartnerships'
import EmptyTableTypography from '../templates/EmptyTableTypography/EmptyTableTypography'

interface TableProps extends WithStyles<typeof styles> {
	loading: boolean
	headers: TableHeader[]
	rowItems: any[]
	onSortClick: (direction: string, orderBy: string) => void
	sortDirection: Sort
	orderBy: string
	paginationFooter?: boolean
	itemsLabel: string
}

type TableWrapperState = Readonly<typeof initialState>

const initialState = {
	rowsPerPage: 20,
	page: 0,
}

const getInitialState = (props: TableProps): TableWrapperState => {
	return initialState
}

export class TableWrapper extends React.Component<TableProps, TableWrapperState> {
	public readonly state: TableWrapperState = getInitialState(this.props)

	handleChangePage = (event: any, page: number) => {
		this.setState({ page })
	}

	handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({ rowsPerPage: +event.target.value })
	}

	handleSortClick = (prop: string) => (e: React.MouseEvent<HTMLInputElement>) => {
		const { onSortClick, sortDirection } = this.props
		onSortClick(sortDirection, prop)
	}

	render() {
		const { classes, headers, itemsLabel, loading, sortDirection, orderBy, rowItems, paginationFooter } = this.props
		const { page, rowsPerPage } = this.state

		return (
			<Paper className={classes.root} square={true} elevation={2} data-auto="main-table-template-wrapper">
				<Collapse in={!loading} timeout={{ enter: 700 }}>
					<Table className={classes.table} data-auto="main-table">
						<TableHead className={classes.tableHeader} data-auto="main-table-header">
							<TableRow className={classes.tableRow}>
								{headers &&
									headers.map((header: HeaderItem, index: number) => {
										const { label, prop } = header

										return (
											header && (
												<TableCell key={index} className={ (prop=='quest') ? classes.quest: classes.headCellItem} data-auto={`table-header-row-item-${prop}`}>
													<Tooltip title="Sort" placement="right" enterDelay={300}>
														<TableSortLabel
															active={prop.toLowerCase() === orderBy.toLowerCase()}
															direction={sortDirection}
															onClick={this.handleSortClick(prop)}
															style={{ color: grey[50] }}
														>
															<Typography variant="button" className={classes.typographyHeader}>
																{label}
															</Typography>
														</TableSortLabel>
													</Tooltip>
												</TableCell>
											)
										)
									})}
							</TableRow>
						</TableHead>
						<TableBody data-auto="main-table-body">
							{rowItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: TableItem, index: number) => {
								const { title, content, isEnrolledAfterOutcome } = item

								return (
									<TableRow key={index} className={classes.row} data-auto="main-table-row">
										<TableCell component="th" scope="row" className={classes.mainColumnItem} data-auto={`row-${index}-title-item`}>
											<NavLink to={title.url} className={classes.linkBtn} data-auto="row-item-title-link">
												<Typography variant="button" className={classes.link} data-auto="row-item-title-label">
													{title.label}
												</Typography>
											</NavLink>
										</TableCell>
										{content &&
											content.map((contentItem: any, i: number) => (
												<TableCell key={i} data-auto={`row-${index}-content-item`} className={(contentItem.column == 'quest') ? classes.quest : classes.tableCell}>
													<Typography variant="body1" className={(isEnrolledAfterOutcome && contentItem.label === 'Outcome Start Date')? classes.red : ''}>
														{contentItem.isDate
															? !!contentItem.value && (
																	<time dateTime={moment(contentItem.value).format('MM/DD/YYYY')}>{moment(contentItem.value).format('MM/DD/YYYY')}</time>
															  )
															: contentItem.value}
													</Typography>
												</TableCell>
											))}
									</TableRow>
								)
							})}
						</TableBody>
						{paginationFooter && (
							<TableFooter data-auto="main-table-footer">
								<TableRow className={classes.footerRow}>
									<TableCell colSpan={headers.length}>
										<TablePagination
											classes={{
												toolbar: classes.toolbar,
											}}
											rowsPerPageOptions={[20, 40, 60]}
											component="div"
											count={rowItems.length}
											rowsPerPage={rowsPerPage}
											page={page}
											backIconButtonProps={{
												'aria-label': 'previous-page',
											}}
											nextIconButtonProps={{
												'aria-label': 'next-page',
											}}
											SelectProps={{
												'aria-label': 'items-per-page-select',
												inputProps: {
													'data-auto': 'items-per-page-select',
												},
											}}
											onChangePage={this.handleChangePage}
											onChangeRowsPerPage={this.handleChangeRowsPerPage}
											data-auto="main-table-footer-pagination"
										/>
									</TableCell>
								</TableRow>
							</TableFooter>
						)}
					</Table>
					{!rowItems.length && <EmptyTableTypography label={itemsLabel} />}
				</Collapse>
			</Paper>
		)
	}
}

const styles = (theme: Theme) =>
	createStyles({
		toolbar: {
			[theme.breakpoints.down('sm')]: {
				display: 'inline-flex',
			},
		},
		root: {
			flexGrow: 1,
			overflowX: 'scroll',
		},
		table: {},
		tableHeader: {
			backgroundColor: blue[900],
		},
		tableRow: {
			colSpan: 2,
		},
		headCellItem: {
			textOverflow: 'ellipsis',
      paddingRight:'0px'
		},
		mainColumnItem: {
			textOverflow: 'ellipsis',
      paddingRight:'0px',
      minWidth:'19em'
		},
		row: {
			'&:nth-of-type(odd)': {
				backgroundColor: grey[200],
			},
			'&:hover': {
				backgroundColor: blueGrey[100],
			},
		},
		footerRow: {
			padding: `${theme.spacing.unit}px`,
		},
		linkBtn: {
			textDecoration: 'none',
		},
		link: {
			transition: 'transform 200ms, color 200ms, letter-spacing 500ms',
			textTransform: 'none',
			'&:hover': {
				color: theme.palette.primary[900],
				[theme.breakpoints.up('md')]: {
					transform: 'scale(1, 1.25)',
					letterSpacing: '0.1em',
				},
			},
		},
		typographyHeader: {
			color: grey[50],
		},
    red:{
      color: 'red'
    },
    tableCell:{
      paddingRight:'0px'
    },
    quest:{
      maxWidth:'70px',
      padding:'0px',
      paddingLeft:'15px'
    },
    questCell:{
      maxWidth:'2px',
      overflow:'auto',
      paddingRight:'0px'
    }
	})

export default withStyles(styles)(TableWrapper)
